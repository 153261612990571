@import "~bootstrap/scss/bootstrap";

$primary-color-dark:   #303F9F;
$primary-color:        #3F51B5;
$primary-color-light:  #C5CAE9;
$primary-color-text:   #FFFFFF;
$accent-color:         #607D8B;
$primary-text-color:   #212121;
$secondary-text-color: #757575;
$divider-color:        #BDBDBD;

.container {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

pre {
  display: unset !important;
  font-family: unset !important;
  white-space: unset !important;
  margin: unset !important;
}
